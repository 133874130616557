<template lang="pug">
v-skeleton-loader.mt-5(
  v-if="isLoadingFile"
  max-width="300"
  type="card")
div(v-else)
  div(v-if="listFile?.length").d-flex.flex-wrap
    div(v-for="(file, index) in listFile" :key="file?.id || index").pa-4.photoDocument-block
      v-skeleton-loader.mx-auto.mt-5(
        v-if="isLoadingSaveFile && selectedFileForEdit?.id === file.id"
        :width="maxWidth"
        type="card")
      div(v-else)
        div(v-if="!isHiddenButton")
          ActionButtons(
            :isShowDelete="checkAccess(documentType, 'deleteFile', sailorDocument, file)"
            :isShowEdit="isShowEdit && checkAccess('documentManipulation') && ['jpeg', 'png', 'jpg'].includes(file.type_file?.toLowerCase())"
            @deleteFile="removeFile"
            @editFile="openFileForEdit"
            :item="file"
          ).d-flex.justify-end
        a(:href="file.url" target="_blank").d-block
          div.photoDocument-block__image
            img.image(
              v-if="['jpeg', 'png', 'jpg'].includes(file.type)"
              :src="file.url"
              :alt="documentType"
              :width="maxWidth")
            embed(
              v-else
              :src="file.url"
              width="324"
              height="324"
            ).d-block
      div(v-if="file.is_delete").white--text.text-end
        span.black.pa-2 {{ $t('deleted') }}
      p(v-if="file.name").photoDocument-name
        a(:href="file.url" target="_blank") {{ file.name }}
  div(v-else)
    div.subtitle-2 {{ $t('infoAboutFileNotExist') }}
  v-dialog(v-model="EditFileModal" max-width="600" max-height="600" width="fit-content")
    div.position-relative
      Cropper(ref="newCropper" @change="updateFile")

</template>

<script>
import { addNeedFieldForViewFilesV2 } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { SUCCESS_CODE } from '@/configs/constants'
import { mapActions } from 'vuex'
// import { downloadBlob } from '../../../mixins/main'

export default {
  name: 'ViewerFile',
  components: {
    ActionButtons: () => import('./ActionButtons.vue'),
    Cropper: () => import('@/components/atoms/Cropper.vue')
  },
  props: {
    files: { type: Array, default: () => [] },
    sailorDocument: { type: Object, default: () => ({}) },
    documentType: { type: String, default: () => '' },
    isShowEdit: { type: Boolean, default: false },
    deleteFile: { type: Function, default: () => ({}) },
    maxWidth: { type: Number, default: 250 },
    removeOnce: { type: Boolean, default: false },
    editFile: { type: Function, default: () => ({}) },
    isHiddenButton: { type: Boolean, default: false }
  },
  data () {
    return {
      checkAccess,
      isLoadingFile: false,
      listFile: [],
      isLoadingSaveFile: false,
      selectedFileForEdit: null,
      EditFileModal: false
    }
  },
  watch: {
    files: {
      handler () {},
      deep: true
    }
  },
  beforeMount () {
    !this.listFile.length && this.getOneFile()
  },
  methods: {
    ...mapActions(['getFile']),
    async getOneFile () {
      this.isLoadingFile = true
      const files = await Promise.all(this.files.map(async el => {
        let responseFile = await this.getFile(el.url || el.photo)
        if (SUCCESS_CODE.includes(responseFile.code)) {
          if (el.type !== 'pdf' && responseFile.type === 'application/pdf') {
            // downloadBlob(responseFile.slice(0, responseFile.size, 'image/jpeg'), 'image', el.type)
            responseFile = responseFile.slice(0, responseFile.size, 'image/jpeg')
          }
        }
        responseFile.photo = el?.url || el?.photo
        responseFile.id = el?.id
        responseFile.is_delete = el?.is_delete || false
        return responseFile
      }))
      if (!files) return
      files.forEach(file => {
        this.getFile(file?.url || file?.photo).then(resp => {
          if (file.type !== 'pdf' && resp.type === 'application/pdf') {
            // downloadBlob(resp.slice(0, resp.size, 'image/jpeg'), 'image', file.type)
          }
        })
      })
      this.listFile = files
      this.listFile = await addNeedFieldForViewFilesV2(files)
      this.isLoadingFile = false
    },
    async removeFile (item) {
      const response = await this.deleteFile({ ...this.$route.params, fileId: item.id, removeOnce: this.removeOnce })
      if (SUCCESS_CODE.includes(response.code)) {
        if (item.is_delete || this.removeOnce) {
          this.listFile = this.listFile.filter(file => item.id !== file.id)
          this.$notification.success('notify.success.deleteFile')
        } else {
          this.listFile = this.listFile.filter(file => {
            if (file.id === item.id) file.is_delete = true
            return file
          })
          this.$notification.success('notify.success.goToStatusDelete')
        }
      }
    },
    async openFileForEdit (item) {
      let file = item.new_file ? item.new_file : await this.getFile(item.url)
      this.EditFileModal = true
      setTimeout(() => this.$refs.newCropper.setImage(file))
      this.selectedFileForEdit = item
    },
    async updateFile (item) {
      this.isLoadingSaveFile = true
      let data = {
        ...this.$route.params,
        fileId: this.selectedFileForEdit.id,
        media: {
          new_file: item
        }
      }
      this.$refs.newCropper.clearCropper()
      this.EditFileModal = false
      const response = await this.editFile(data)
      if (SUCCESS_CODE.includes(response.code)) {
        this.listFile = this.listFile.map(el => {
          if (el.id === this.selectedFileForEdit.id) {
            el.url = URL.createObjectURL(item)
            el.new_file = item
          }
          return el
        })
        this.selectedFileForEdit = null
      }
      this.isLoadingSaveFile = false
    }
  }
}
</script>
